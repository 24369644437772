<template>
	<page-container :page="page">
		<main-content
			:title="page.title || page.header"
			:color="page.color"
			:content="page.content"
			:heading-image="page.headingImages && page.headingImages[0]"
		/>

		<section v-if="page.packages" class="packages">
			<div class="row">
				<div v-for="item in page.packages" :key="item.ID" class="package">
					<a v-if="item.link" :href="item.link" class="image">
						<picture>
							<source :srcset="item.imageWebp" type="image/webp" />
							<source :srcset="item.image" />
							<img :src="item.image" :alt="item.imageAlt" loading="lazy" />
						</picture>
					</a>
					<a
						v-else-if="item.bookingID"
						:href="'/?selfbook=true&hotel=41236&adult=2&roomId=' + item.bookingID"
						target="_self"
						class="image"
					>
						<picture>
							<source :srcset="item.imageWebp" type="image/webp" />
							<source :srcset="item.image" />
							<img :src="item.image" :alt="item.imageAlt" loading="lazy" />
						</picture>
					</a>
					<div v-else class="image">
						<picture>
							<source :srcset="item.imageWebp" type="image/webp" />
							<source :srcset="item.image" />
							<img :src="item.image" :alt="item.imageAlt" loading="lazy" />
						</picture>
					</div>
					<div class="content">
						<h2>{{ item.header }}</h2>
						<div v-parse-links v-html="item.content" />
						<a v-if="item.link" :href="item.link" class="button">
							<span>{{ item.button }}</span>
						</a>
						<a
							v-else-if="item.bookingID"
							class="button book-button"
							:href="'/?selfbook=true&hotel=41236&adult=2&roomId=' + item.bookingID"
							target="_self"
						>
							<span>{{ item.button }}</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>

<style lang="scss" scoped>
.packages {
	.package {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		max-width: 1100px;
		margin: 50px auto;

		.content {
			width: 50%;
			background: none;
		}

		.image {
			width: 46%;
		}

		&:nth-child(2) {
			flex-flow: row-reverse wrap;
		}
	}
}

@media (max-width: 980px) {
	.packages {
		.package {
			width: 98%;
			margin: 40px auto;

			.content {
				width: 100%;
			}

			.image {
				width: 100%;
			}

			&:nth-child(2) {
				margin-left: auto;
			}
		}
	}
}
</style>
